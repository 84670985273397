import axios from 'axios';
import InterceptHandler from './ServiceInterceptor';
import { isDevInstance, deviceTypeCheck, getEmail } from '../helpers/utils';
import Cookie from 'js-cookie';

const Api = () => {
  const isDetailParamAvailable = (details) => {
    return details && Object.getOwnPropertyNames(details).length > 0;
  };

  const setDetailParams = (params) => {
    let details = {};
    if (
      !params.details ||
      (isDetailParamAvailable(params.details) && !params.details.email)
    ) {
      details = { email: getEmail() };
    }

    if (isDetailParamAvailable(params.details)) {
      details = { ...params.details, ...details };
    }

    return details;
  };

  return {
    api: (() => {
      return axios.create({
        baseURL: isDevInstance()
          ? 'https://partnernewdev.sanmina.com/api'
          : '/api',
      });
    })(),
    helpdeskApi: (() => {
      return axios.create({
        baseURL: isDevInstance()
          ? 'https://partnernewdev.sanmina.com/troubleticket'
          : '/troubleticket',
      });
    })(),
    formatParams: (params, getState = null) => {
      let portalAccess = { portalAccess: '' };
      let authorizationToken = { authorization_token: '' };
      let role = {},
        email = { email: getEmail() },
        details = setDetailParams(params);
      let deviceType = { deviceType: deviceTypeCheck() };
      if (getState) {
        const { defaultAccess, authorization, userRole } =
          getState().authorization.initAuthorization;
        portalAccess =
          defaultAccess !== undefined
            ? { portalAccess: defaultAccess }
            : { portalAccess: '' };
        authorizationToken =
          authorization !== undefined
            ? { authorization_token: authorization }
            : { authorization_token: '' };
        role = userRole !== undefined ? { userRole } : {};

        if (defaultAccess === 'PC') {
          email = { email: getEmail() };
          authorizationToken = {
            ...authorizationToken,
            ...email,
          };
        }
      }

      if (isDevInstance()) {
        email = { email: getEmail() };
        authorizationToken = {
          ...authorizationToken,
          ...email,
        };
        console.log(
          JSON.stringify({
            TXN: [
              {
                ...params,
                details,
                ...portalAccess,
                ...role,
                ...email,
                authorizationDetail: {
                  ...authorizationToken,
                },
                ...deviceType,
              },
            ],
          })
        );
      }

      return {
        TXN: [
          {
            ...params,
            details,
            ...portalAccess,
            ...role,
            ...email,
            authorizationDetail: {
              ...authorizationToken,
            },
            ...deviceType,
          },
        ],
      };
    },
  };
};

const Service = Api();

Service.api.interceptors.request.use(
  (config) => {
    return InterceptHandler.handleRequest(config);
  },
  (error) => {
    return InterceptHandler.handleRequestError(error);
  }
);

let sessionExpireResCount = 1;

Service.api.interceptors.response.use(
  (response) => {
    return InterceptHandler.handleResponse(
      response,
      () => sessionExpireResCount++
    );
  },
  (error) => {
    return InterceptHandler.handleResponseError(error);
  }
);

export default Service;
